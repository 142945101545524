// ROUTE
export const APP_ROUTE = {
  LOGIN_ROUTE: '/login',
  REQUIRE_CHANGE_PASSWORD_ROUTE: '/require-change-password',
  HOME: '/',
};

// API
export const UPDATE_PASSWORD_WITH_ID = userId => `auth/password/update/${userId}`;

export const getSyntaxJsonUnquoteExtract = field => `->>'$.${field}'`;

// DATETIME
export const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';
export const DATETIME_DISPLAY_WITH_HOURS = 'DD/MM/YYYY HH';
export const DATETIME_DISPLAY_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATETIME_DISPLAY_FORMAT_WITH_SECONDS = 'DD/MM/YYYY HH:mm:ss';
export const DATETIME_DISPLAY_FORMAT_WITH_MILLISECOND = 'DD/MM/YYYY HH:mm:ss.SSS';
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]';

export const STATUS_REQUIRED_CHANGE_PASSWORD = 207;

export const API_URL = {
  RESET_CREDENTIAL: 'api/brand/reset-credential',
  GET_PERM_BY_ROLE: roleId => `api/role/${roleId}/perms-by-role`,
  AUTHORIZE_FIREBASE_BO: 'api/auth/authorize-firebase-bo',
  REPLAY_URL_FOR_BET: betId => `api/bet/${betId}/replay-url`,
};

export const CREDENTIAL_TYPE = {
  NODE_RSA_KEY: 'NODE-RSA-KEY',
  SECRET_KEY: 'SECRET-KEY',
  API_KEY: 'API-KEY',
};

export const FILTER_PREFIX_ON_VALUE = {
  EQUALS: '**||$eq||**',
  NOT_EQUALS: '**||$ne||**',
  GREATER_THAN: '**||$gt||**',
  LOWER_THAN: '**||$lt||**',
  GREATER_THAN_EQUALS: '**||$gte||**',
  LOWER_THAN_EQUALS: '**||$lte||**',
  STARTS: '**||$starts||**',
  ENDS: '**||$ends||**',
  CONTAINS: '**||$cont||**',
  EXCLUDES: '**||$excl||**',
  IN: '**||$in||**',
  NOT_IN: '**||$notin||**',
  IS_NULL: '**||$isnull||**',
  NOT_NULL: '**||$notnull||**',
  BETWEEN: '**||$between||**',
  EQUALS_LOW: '**||$eqL||**',
  NOT_EQUALS_LOW: '**||$neL||**',
  STARTS_LOW: '**||$startsL||**',
  ENDS_LOW: '**||$endsL||**',
  CONTAINS_LOW: '**||$contL||**',
  EXCLUDES_LOW: '**||$exclL||**',
  IN_LOW: '**||$inL||**',
  NOT_IN_LOW: '**||$notinL||**',
  IGNORED: '**||$ignores||**',
};

export const SYSTEM_ADMIN_LEVEL = 20;
export const GROUP_ADMIN_LEVEL = 10;

// Field Constants
export const ID_EMPTY = 'N/A';

// If items exported file great than max => display "Show More" button.
export const NUMBER_TO_SHOW_EXPORTED_FILES = 3;
export const NUMBER_TO_SHOW_PREPAID_ACTIVE_STATUS = 5;

export const NUMBER_TO_SHOW_CHEAT_MODE_CREATED_MESSAGE = 3;

export const isUserSA = user => {
  if (user?.role?.type === 'SYSTEM' && user?.role?.level === SYSTEM_ADMIN_LEVEL) {
    return true;
  }

  return false;
};

export const WealthListGuesserType = {
  default: 'default',
  custom: 'custom',
};

export const AXIOS_RETRIES = 2;

export const BODY_COLOR_VAR = '--bodyColor';

export const LIST_CHEAT_ENV = ['backend', 'pre-staging', 'marketing'];

export const AppThemeEnum = {
  DEFAULT: 'default',
  GREEN_PACIFIC_QUEEN: 'green-pacific-queen',
};

export const AppBrandEnum = {
  NAGA: 'naga',
  MBO: 'mbo',
};

export const ROLE_TYPE = {
  system: 'SYSTEM',
  group: 'GROUP',
};

export const PrepaidStatusEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  APPROVED: 'APPROVED',
};

export const WalletImplementationEnum = {
  SEAMLESS: 'SEAMLESS',
  TRANSFER: 'TRANSFER',
};

export const MULTI_SELECT_OPTION_ALL = {
  value: 'select-all',
  text: 'Select All',
};

export const CurrencyDisplayModeEnum = {
  NORMAL: 'NORMAL',
  THIN: 'THIN',
};

export const RESOURCE_GROUP = {
  ROOT: {
    name: 'root',
    index: -1,
  },
  AUTHENTICATION: {
    name: 'authentication',
    index: 1,
  },
  GAMES: {
    name: 'games',
    index: 2,
  },
  REPORT: {
    name: 'report',
    index: 3,
  },
  CUSTOMER_SERVICE: {
    name: 'customer-service',
    index: 4,
  },
  BOOST: {
    name: 'boost',
    index: 5,
  },
  MARKETING_TOOL: {
    name: 'marketing-tool',
    index: 6,
  },
  SUPPORT: {
    name: 'support',
    index: 7,
  },
  SYSTEM_SETUP: {
    name: 'system-setup',
    index: 8,
  },
  OTHERS: {
    name: 'others',
    index: 9,
  },
};

export const UUID_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const PREPAID_ISSUER = {
  nagaGames: 'NG',
  brand: 'BRAND',
};

export const PREPAID_APPROVAL_STATUS = {
  pending: 'PENDING',
  approved: 'APPROVED',
};

export const DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT = 'USD';

export const CURRENCY_TYPES = {
  fiat: 'FIAT',
  crypto: 'CRYPTO',
};

export const BET_STATUS = {
  PENDING: 'PENDING', // Bet is on process of Gamify server...
  RESOLVED: 'RESOLVED', // Bet is completed - both operator and Gamify server record this bet successfully
  OPERATOR_UNKNOWN_ERROR: 'OPERATOR_UNKNOWN_ERROR', // Gamify received error from operator -> this bet will be marked at error
  CANCELED: 'CANCELED', // Bet is canceled of Gamify server...
  PENDING_CANCELED: 'PENDING_CANCELED', // Bet is on waiting for call api cancel bet from Operator server
  PAYOUT_OPERATOR_ERROR: 'PAYOUT_OPERATOR_ERROR', // Gamify received error on step PAYOUT from operator
  PLACEBET_OPERATOR_ERROR: 'PLACEBET_OPERATOR_ERROR', // Gamify received error on step PLACEBET from operator
  PAYOUT_CHECK_FAIL: 'PAYOUT_CHECK_FAIL', // Bet is not valid PAYOUT response from operator
  PLACEBET_CHECK_FAIL: 'PLACEBET_CHECK_FAIL', //  Bet is not valid PLACEBET response from operator
  PLACEBET_RESOLVED: 'PLACEBET_RESOLVED', //  Bet finished place bet successfully after call to operator 200 with response valid
  // Cancel fail, payout fail statuses are used for seamless reconciliation retry. When number of retry times exceed max limit (currently 5 times)
  CANCEL_FAIL: 'CANCEL_FAIL', // Bet is marked cancel_fail when it exceeds max failed retry in seamless reconciliation
  PAYOUT_FAIL: 'PAYOUT_FAIL', // Bet is marked payout_fail when it exceeds max failed retry in seamless reconciliation
};

export const BET_STATUS_TO_VIEW_REPLAY = [
  BET_STATUS.RESOLVED,
  BET_STATUS.PAYOUT_CHECK_FAIL,
  BET_STATUS.PAYOUT_OPERATOR_ERROR,
  BET_STATUS.PLACEBET_RESOLVED,
  BET_STATUS.PAYOUT_FAIL,
];

export const REPORT_TYPES = {
  OVERALL: 'overall',
  STANDARD: 'standard',
  PREPAID: 'prepaid',
};

export const BET_TYPES = {
  NORMAL: 'NORMAL',
  PREPAID: 'PREPAID',
  WIN_FREESPIN: 'WIN_FREESPIN',
  BUY_FREESPIN: 'BUY_FREESPIN',
  GAMBLE: 'GAMBLE',
  JACKPOT: 'JACKPOT',
  WIN_FREESPIN_LAST: 'WIN_FREESPIN_LAST',
  BUY_FREESPIN_LAST: 'BUY_FREESPIN_LAST',
  GAMBLE_LAST: 'GAMBLE_LAST',
  PREPAID_NORMAL: 'PREPAID_NORMAL',
  PREPAID_WIN_FREESPIN: 'PREPAID_WIN_FREESPIN',
  PREPAID_WIN_FREESPIN_LAST: 'PREPAID_WIN_FREESPIN_LAST',
};

export const BET_CHANNELS = {
  PC: 'PC',
  MOBILE: 'Mobile',
};

export const PLAYER_OPERATOR = {
  INCLUDE: '==',
  EXCLUDE: '!=',
};

export const GAME_FEATURE_VALUE_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
};

export const BUILT_IN_ROLE_NAME = {
  CSO: 'CSO',
};
