/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useTranslate } from 'react-admin';
import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import BetDateRangeInput from '../bet/components/date-range.input';
import { FILTER_PREFIX_ON_VALUE, SERVER_DATETIME_FORMAT } from '../../constant';
import WagerPagination from './components/wager.pagination';

const useStyles = makeStyles({
  filterContainerWrapper: {
    "& *[data-field='created']": {
      order: 1,
    },
    "& *[data-field='wagerId']": {
      order: 2,
    },
    "& *[data-field='playerId']": {
      order: 3,
    },
    "& *[data-field='nativeId']": {
      order: 4,
    },
    "& *[data-field='group']": {
      order: 5,
    },
    "& *[data-field='brand']": {
      order: 6,
    },
    "& *[data-field='currencyType']": {
      order: 7,
    },
    "& *[data-field='currency']": {
      order: 8,
    },
    "& *[data-field='gameId']": {
      order: 9,
    },
    "& *[data-field='game']": {
      order: 10,
    },
    "& *[data-field='betType']": {
      order: 11,
    },
    "& *[data-field='betStatus']": {
      order: 12,
    },
    "& *[data-field='largeWin']": {
      order: 13,
    },
  },
});

function WagerList(props) {
  const styles = useStyles();

  const translate = useTranslate();

  const {
    wagerList, currentWagerPage,
  } = useSelector(({ wager }) => wager);

  const wager = useMemo(() => wagerList[currentWagerPage]?.data || [], [wagerList, currentWagerPage]);

  const filterDefaultValues = useMemo(() => {
    const today = moment().add(1, 'day').startOf('day').format(SERVER_DATETIME_FORMAT);
    const yesterday = moment().subtract(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);

    return {
      created: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday},${today}`,
    };
  }, []);

  return (
    <WealthListGuesser
      {...props}
      controllerProps={{
        data: keyBy(wager, 'id'),
        ids: wager?.map(({ id }) => id),
      }}
      defaultSort={{
        field: 'created',
        order: 'ASC',
      }}
      optionsSearch={{
        excludeSearchFields: ['created', 'largeWin'],
        extraSearchFields: [
          <BetDateRangeInput
            source="created"
            label={translate('resources.bet.fields.created')}
            {...props}
          />,
        ],
      }}
      filterProps={{
        containerClassName: styles.filterContainerWrapper,
      }}
      excludeFields={['largeWin']}
      filterDefaultValues={filterDefaultValues}
      pagination={<WagerPagination />}
      disabledAutoRefetch
    />
  );
}

export default WagerList;
